import List from '@mui/material/List'
import PropTypes from 'prop-types'
import ApiPB from '../qai_hub/public_api_pb'
import { ErrorButton } from './error-button'
import { InfoButton } from './info-button'
import { ListItemWithIcon } from './list-item-with-icon'
import { WarningButton } from './warning-button'

NotificationsList.propTypes = {
  notices: PropTypes.arrayOf(ApiPB.Notice),
}

export default function NotificationsList(props) {
  const errorMessages = props.notices
    .filter((m) => m.getSeverity() == ApiPB.Notice.Severity.ERROR)
    .map((m) => m.getMessage())
  const warningMessages = props.notices
    .filter((m) => m.getSeverity() == ApiPB.Notice.Severity.WARNING)
    .map((m) => m.getMessage())
  const infoMessages = props.notices
    .filter((m) => m.getSeverity() == ApiPB.Notice.Severity.INFO)
    .map((m) => m.getMessage())
  const debugMessages = props.notices
    .filter((m) => m.getSeverity() == ApiPB.Notice.Severity.DEBUG)
    .map((m) => m.getMessage())

  const errorTitle = errorMessages.length == 1 ? 'Error' : 'Errors'
  const warningTitle = warningMessages.length == 1 ? 'Warning' : 'Warnings'

  return (
    <List>
      {errorMessages.length > 0 && (
        <ListItemWithIcon caption={errorTitle} icon={<ErrorButton title={errorTitle} />}>
          {errorMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
      {warningMessages.length > 0 && (
        <ListItemWithIcon caption={warningTitle} icon={<WarningButton title={warningTitle} />}>
          {warningMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
      {infoMessages.length > 0 && (
        <ListItemWithIcon caption="Information" icon={<InfoButton title="Information" />}>
          {infoMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
      {debugMessages.length > 0 && (
        <ListItemWithIcon caption="Debugging Information" icon={<InfoButton title="Debugging Information" />}>
          {debugMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
    </List>
  )
}
