import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ArticleIcon from '@mui/icons-material/Article'
import CropIcon from '@mui/icons-material/Crop'
import DevicesIcon from '@mui/icons-material/Devices'
import EditIcon from '@mui/icons-material/Edit'
import FlagIcon from '@mui/icons-material/Flag'
import PersonIcon from '@mui/icons-material/Person'
import SummarizeIcon from '@mui/icons-material/Summarize'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { getJob, setJobName, useMounted } from '../common/restAPI'
import { displayPsnr, formatSpec } from '../common/utils'
import ApiPB from '../qai_hub/public_api_pb'
import { InfoButton } from './info-button'
import { ListItemWithIcon } from './list-item-with-icon'
import NotificationsList from './NotificationsList'

function SharedShapeGrid(props) {
  let namedTensorTypesList = props.namedTensorTypesList
  let title = props.title
  let jobPb = props.jobPb
  return (
    <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
        <ListItemWithIcon caption={title} icon={<CropIcon />}>
          {namedTensorTypesList && namedTensorTypesList.length > 0 ? (
            <>
              {namedTensorTypesList.map((namedTensorType, index) => {
                let name = namedTensorType.getName()
                let spec = namedTensorType.getTensorType()
                return (
                  <Typography key={index}>
                    <code>{name}</code>: {formatSpec(spec)}
                  </Typography>
                )
              })}
            </>
          ) : (
            <>
              {jobPb.getJobState() == ApiPB.JobState.JOB_STATE_FAILED ? (
                <Typography>Not Parsed</Typography>
              ) : (
                <Typography>Not Yet Parsed</Typography>
              )}
            </>
          )}
        </ListItemWithIcon>
      </List>
    </Grid>
  )
}

SharedShapeGrid.propTypes = {
  namedTensorTypesList: PropTypes.any,
  title: PropTypes.string.isRequired,
  jobPb: PropTypes.any,
}

function SharedMetricsGrid({ metricsList, title, tooltip }) {
  const header = (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography variant="caption" color="gray">
        {title}
      </Typography>
      {tooltip && <InfoButton title={tooltip} />}
    </Stack>
  )

  return (
    <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
        <ListItemWithIcon caption={header} icon={<CropIcon />}>
          {metricsList.map(([key, value]) => (
            <Typography key={key}>
              <code>{key}</code>: {value}
            </Typography>
          ))}
        </ListItemWithIcon>
      </List>
    </Grid>
  )
}

SharedMetricsGrid.propTypes = {
  metricsList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
}

function SharedModelGrid(props) {
  let title = props.title
  let modelPb = props.modelPb
  return (
    <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
        <ListItemWithIcon caption={title} icon={<UploadFileRoundedIcon />}>
          <div className="vertical-align-elements">
            <div className="scrollable">
              <Link to={`/models/${modelPb.getModelId()}`}>{modelPb.getName()}</Link>
            </div>
            <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
              <span className="idbox">{modelPb.getModelId()}</span>
            </div>
          </div>
        </ListItemWithIcon>
      </List>
    </Grid>
  )
}

function getPrecisionString(quantizeDtype) {
  switch (quantizeDtype) {
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_INT8:
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_UINT8: {
      return 'int8'
    }
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_INT16:
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_UINT16: {
      return 'int16'
    }
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_INT4:
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_UINT4: {
      return 'int4'
    }
  }
  return 'Unknown'
}

function getOSName(attributes) {
  const osAttribute = attributes.find((element) => element.startsWith('os:'))
  switch (osAttribute) {
    case 'os:android': {
      return 'Android'
    }
    case 'os:windows': {
      return 'Windows'
    }
    case 'os:ios': {
      if (attributes.includes('format:tablet')) {
        return 'iPadOS'
      }
      return 'iOS'
    }
    case 'macos': {
      return 'macOS'
    }
    // No default
  }
  return ''
}

SharedModelGrid.propTypes = {
  title: PropTypes.string.isRequired,
  modelPb: PropTypes.any,
}

export function JobInfoGrid(props) {
  const [jobPb, setJobPb] = useState(props.jobPb)
  const [isJobBeingRenamed, setIsJobBeingRenamed] = useState(false)
  const [jobLocalName, setJobLocalName] = useState(jobPb.getName())

  const jobType = props.jobType
  const isCompileJob = jobType === ApiPB.JobType.JOB_TYPE_COMPILE
  const isProfileJob = jobType === ApiPB.JobType.JOB_TYPE_PROFILE
  const isInferenceJob = jobType === ApiPB.JobType.JOB_TYPE_INFERENCE
  const isLinkJob = jobType === ApiPB.JobType.JOB_TYPE_LINK
  const isQuantizeJob = jobType === ApiPB.JobType.JOB_TYPE_QUANTIZE

  const jobId = props.jobId
  const profileDetail = props.profileDetail
  const compileDetail = props.compileDetail
  const linkDetail = props.linkDetail
  let versionDetail
  if (profileDetail) {
    versionDetail = profileDetail.getToolVersionsList()
  } else if (compileDetail) {
    versionDetail = compileDetail.getToolVersionsList()
  } else if (linkDetail) {
    versionDetail = linkDetail.getToolVersionsList()
  }
  const hasVersionDetail = versionDetail && versionDetail.length > 0

  const renameJob = () => {
    setIsJobBeingRenamed(false)
    const [mountState] = useMounted()

    setJobName(
      jobId,
      jobLocalName,
      mountState,
      () => {
        // The jobLocalName variable already has the updated value, and that's what will be shown
        // to the user when the input field is hidden.
        getJob(
          jobId,
          mountState,
          (jobPb) => {
            let jobPbInner
            switch (jobType) {
              case ApiPB.JobType.JOB_TYPE_COMPILE:
                jobPbInner = jobPb.getCompileJob()
                break
              case ApiPB.JobType.JOB_TYPE_PROFILE:
                jobPbInner = jobPb.getProfileJob()
                break
              case ApiPB.JobType.JOB_TYPE_INFERENCE:
                jobPbInner = jobPb.getInferenceJob()
                break
              case ApiPB.JobType.JOB_TYPE_QUANTIZE:
                jobPbInner = jobPb.getQuantizeJob()
                break
              case ApiPB.JobType.JOB_TYPE_LINK:
                jobPbInner = jobPb.getLinkJob()
                break
            }
            setJobPb(jobPbInner)
          },
          (err) => {
            console.error(err)
          },
        )
      },
      (err) => {
        console.error(err)
        // Reset the name to what we got in props, aka the previous name of the job.
        setJobLocalName(jobPb.getName())
      },
    )
  }

  const cancelJobRename = () => {
    setIsJobBeingRenamed(false)
    // Reset the name to what we got in props, aka the previous name of the job.
    setJobLocalName(jobPb.getName())
  }

  const renderTime = () => {
    let timesTitleParts = ['Submission']
    let timesElements = [<Typography key="submission">{jobPb.getCreationTime().toDate().toLocaleString()}</Typography>]
    if (jobPb.getCompletionTime()) {
      timesTitleParts.push('Completion')
      timesElements.push(
        <Typography key="completion">{jobPb.getCompletionTime().toDate().toLocaleString()}</Typography>,
      )
    }
    let timesTitle = timesTitleParts.join(' / ') + ' Time'

    return [timesTitle, timesElements]
  }

  if (jobPb) {
    let namedTensorTypesList
    if ((isCompileJob || isProfileJob || isInferenceJob || isQuantizeJob) && jobPb.getTensorTypeList?.()) {
      namedTensorTypesList = jobPb.getTensorTypeList().getTypesList()
    }
    let [timesTitle, timesElements] = renderTime()

    return (
      <Grid container spacing={2}>
        <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
            <ListItemWithIcon caption="Name" icon={<ArticleIcon />}>
              {isJobBeingRenamed ? (
                <Stack direction="column">
                  <input
                    autoFocus
                    value={jobLocalName}
                    onChange={(e) => {
                      setJobLocalName(e.currentTarget.value)
                    }}
                  ></input>
                  <Stack direction="row">
                    <Button onClick={renameJob}>Save</Button>
                    <Button onClick={cancelJobRename}>Cancel</Button>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="row">
                  <div className="scrollable">
                    <Typography color="black">{jobPb.getName()}</Typography>
                  </div>
                  <IconButton
                    onClick={() => {
                      setIsJobBeingRenamed(true)
                    }}
                  >
                    <EditIcon
                      color="primary"
                      style={{
                        height: '20px',
                        margin: '-6px 0 0 -6px',
                      }}
                      className="test"
                    />
                  </IconButton>
                </Stack>
              )}
            </ListItemWithIcon>
          </List>
        </Grid>
        {!isQuantizeJob && !isLinkJob && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItemWithIcon caption="Target Device" icon={<DevicesIcon />}>
                <div>{jobPb.getDevice().getName()}</div>
                <div style={{ fontSize: '0.9em' }}>
                  {jobPb.getDevice().getOs() && getOSName(jobPb.getDevice().getAttributesList())}&nbsp;
                  {jobPb.getDevice().getOs()}
                </div>
                <div style={{ fontSize: '0.9em' }}>{jobPb.getDevice().getSocDescription()}</div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItemWithIcon caption="Creator" icon={<PersonIcon />}>
              <Typography color="black">{jobPb.getUser().getEmail()}</Typography>
            </ListItemWithIcon>
          </List>
        </Grid>
        {!isLinkJob && (
          <SharedModelGrid
            title={isCompileJob || isQuantizeJob ? 'Source Model' : 'Target Model'}
            modelPb={jobPb.getModel()}
          />
        )}
        {isLinkJob && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption="Source Models" icon={<UploadFileRoundedIcon />}>
                {jobPb
                  .getModels()
                  .getModelsList()
                  .map((modelPb, index) => (
                    <div key={index} className="vertical-align-elements">
                      <div className="scrollable">
                        <Link to={`/models/${modelPb.getModelId()}`}>{modelPb.getName()}</Link>
                      </div>
                      <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
                        <span className="idbox">{modelPb.getModelId()}</span>
                      </div>
                    </div>
                  ))}
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {isInferenceJob && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption={'Input Dataset'} icon={<CropIcon />}>
                <div className="vertical-align-elements">
                  <div className="scrollable">{jobPb.getDataset().getName()}</div>
                  <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
                    <span className="idbox">{jobPb.getDataset().getDatasetId()}</span>
                  </div>
                </div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {!isInferenceJob && !isLinkJob && (
          <SharedShapeGrid namedTensorTypesList={namedTensorTypesList} title={'Input Specs'} jobPb={jobPb} />
        )}
        <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
            <ListItemWithIcon caption={timesTitle} icon={<AccessTimeIcon />}>
              {timesElements}
            </ListItemWithIcon>
          </List>
        </Grid>
        {(isCompileJob || isQuantizeJob) && jobPb.getCalibrationDataset() && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption={'Quantization Calibration Dataset'} icon={<CropIcon />}>
                <div className="vertical-align-elements">
                  <div className="scrollable">{jobPb.getCalibrationDataset().getName()}</div>
                  <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
                    <span className="idbox">{jobPb.getCalibrationDataset().getDatasetId()}</span>
                  </div>
                </div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {jobPb.getOptions().trim() && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption="Options" icon={<FlagIcon />}>
                <Typography>
                  <code>{jobPb.getOptions()}</code>
                </Typography>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {hasVersionDetail && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption="Versions" icon={<SummarizeIcon />}>
                {versionDetail.map((toolVersion) => (
                  <div key={toolVersion.getName()}>
                    {toolVersion.getName()} : {toolVersion.getVersion()}
                  </div>
                ))}
                <div>AI Hub : {props.hubVersion}</div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {isQuantizeJob && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption="Model Precision" icon={<SummarizeIcon />}>
                <div>Weights: {getPrecisionString(jobPb.getWeightsDtype())}</div>
                <div>Activations: {getPrecisionString(jobPb.getActivationsDtype())}</div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {props.notices.length > 0 && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <NotificationsList notices={props.notices} />
          </Grid>
        )}
      </Grid>
    )
  }

  return 'Loading Information...'
}

JobInfoGrid.propTypes = {
  jobPb: PropTypes.any,
  jobId: PropTypes.string.isRequired,
  jobType: PropTypes.number,
  profileDetail: PropTypes.object,
  compileDetail: PropTypes.object,
  linkDetail: PropTypes.object,
  hubVersion: PropTypes.string,
  notices: PropTypes.arrayOf(ApiPB.Notice),
}

export function JobTargetInfoGrid(props) {
  const { jobPb, hasTargetTensorType, psnrValues } = props

  if (jobPb) {
    let namedTargetTensorTypesList
    if (hasTargetTensorType && jobPb.getTargetTensorTypeList()) {
      namedTargetTensorTypesList = jobPb.getTargetTensorTypeList().getTypesList()
    }

    return (
      <Grid container spacing={2}>
        <SharedModelGrid title={'Target Model'} modelPb={jobPb.getTargetModel()} />
        {hasTargetTensorType && (
          <SharedShapeGrid namedTensorTypesList={namedTargetTensorTypesList} title={'Input Specs'} jobPb={jobPb} />
        )}
        {psnrValues.length > 0 && (
          <SharedMetricsGrid
            metricsList={psnrValues.map(([key, value]) => [key, displayPsnr(value)])}
            title="PSNR"
            tooltip="A measure of how close the quantized model outputs are to the original model's outputs. A value of 30 or more is generally considered good."
          />
        )}
      </Grid>
    )
  }

  return 'Loading Information...'
}

JobTargetInfoGrid.propTypes = {
  jobPb: PropTypes.any,
  hasTargetTensorType: PropTypes.bool,
  psnrValues: PropTypes.any,
}
