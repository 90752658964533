import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import DownloadIcon from '@mui/icons-material/Download'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FolderIcon from '@mui/icons-material/Folder'
import VisualizeIcon from '@mui/icons-material/ImageSearch'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import UnspecifiedComputeUnitIcon from '@mui/icons-material/QuestionMark'
import ShareIcon from '@mui/icons-material/Share'
import { Divider } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntervalEffect } from '../common/hooks/use-interval-effect'
import { downloadFile, getJob, getJobResult, getProducedModels, useMounted } from '../common/restAPI'
import {
  HeaderPaper,
  capitalizeFirstLetter,
  computeUnitToColor,
  computeUnitToString,
  getLayersTableData,
  jobStateToString,
  jobTypeToString,
} from '../common/utils'
import ApiPB from '../qai_hub/public_api_pb'
import { NotFoundPage } from '../routes/not-found-page'
import { InfoButton } from './info-button'
import JobDetailedMetrics from './JobDetailedMetrics'
import JobInferenceMetrics from './JobInferenceMetrics'
import { JobInfoGrid, JobTargetInfoGrid } from './JobInfoGrid'
import { JobGenericLog, JobRuntimeLog } from './JobLog'
import JobRuntimeConfig from './JobRuntimeConfig'
import { JobStateIcon, getJobStateIconTooltip } from './JobStateIcon'
import { ListItemWithIcon } from './list-item-with-icon'
import { ShareDialog } from './share-dialog'

function formatNumber(number) {
  if (Number.isInteger(number)) {
    return number.toLocaleString()
  }
  return number
}

export default function ViewJob(props) {
  const { apiFetcher, jobId } = props
  const [jobPb, setJobPb] = useState()
  const [hubVersion, setHubVersion] = useState()
  const [notices, setNotices] = useState([])
  const [jobResultPb, setJobResultPb] = useState()
  const [trigger404, setTrigger404] = useState(false)
  const [jobType, setJobType] = useState()
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)
  const [hasShareAccess, setHasShareAccess] = useState(false)
  const [producedModelList, setProducedModelList] = useState([])

  const isCompileJob = jobType === ApiPB.JobType.JOB_TYPE_COMPILE
  const isProfileJob = jobType === ApiPB.JobType.JOB_TYPE_PROFILE
  const isInferenceJob = jobType === ApiPB.JobType.JOB_TYPE_INFERENCE
  const isLinkJob = jobType === ApiPB.JobType.JOB_TYPE_LINK
  const isQuantizeJob = jobType === ApiPB.JobType.JOB_TYPE_QUANTIZE

  const POLL_INTERVAL = 5000 // 5 seconds

  const infoButtonKernelsTitle = 'Kernels and/or resources used by layer at runtime. The values are backend-specific.'
  const infoButtonCyclesTitle = 'Compute cycles are only supported on some hardware.'
  const infoButtonTimeTitle =
    'Time to execute the layer. Note that if compute cycles and execution time are both shown, the time presented is an estimate only.'

  const setJobPbOr404 = (jobPb) => {
    let newJobType = ApiPB.JobType.JOB_TYPE_UNSPECIFIED
    let name = ''

    setHubVersion(jobPb.getHubVersion())
    setNotices(jobPb.getNoticesList())
    if (jobPb.getJobCase() === ApiPB.Job.JobCase.COMPILE_JOB) {
      setJobPb(jobPb.getCompileJob())
      newJobType = ApiPB.JobType.JOB_TYPE_COMPILE
      name = jobPb.getCompileJob().getName()
    } else if (jobPb.getJobCase() === ApiPB.Job.JobCase.PROFILE_JOB) {
      setJobPb(jobPb.getProfileJob())
      newJobType = ApiPB.JobType.JOB_TYPE_PROFILE
      name = jobPb.getProfileJob().getName()
    } else if (jobPb.getJobCase() === ApiPB.Job.JobCase.INFERENCE_JOB) {
      setJobPb(jobPb.getInferenceJob())
      newJobType = ApiPB.JobType.JOB_TYPE_INFERENCE
      name = jobPb.getInferenceJob().getName()
    } else if (jobPb.getJobCase() === ApiPB.Job.JobCase.QUANTIZE_JOB) {
      setJobPb(jobPb.getQuantizeJob())
      newJobType = ApiPB.JobType.JOB_TYPE_QUANTIZE
      name = jobPb.getQuantizeJob().getName()
    } else if (jobPb.getJobCase() === ApiPB.Job.JobCase.LINK_JOB) {
      setJobPb(jobPb.getLinkJob())
      newJobType = ApiPB.JobType.JOB_TYPE_LINK
      name = jobPb.getLinkJob().getName()
    }
    setJobType(newJobType)
    const jobTypeStr = capitalizeFirstLetter(jobTypeToString(newJobType))
    document.title = `${name} ${jobTypeStr} Job | AI Hub`
  }

  const setJobResultPbOr404 = (jobResultPb) => {
    if (jobResultPb.getResultCase() === ApiPB.JobResult.ResultCase.COMPILE_JOB_RESULT) {
      setJobResultPb(jobResultPb.getCompileJobResult())
    } else if (jobResultPb.getResultCase() === ApiPB.JobResult.ResultCase.PROFILE_JOB_RESULT) {
      setJobResultPb(jobResultPb.getProfileJobResult())
    } else if (jobResultPb.getResultCase() === ApiPB.JobResult.ResultCase.LINK_JOB_RESULT) {
      setJobResultPb(jobResultPb.getLinkJobResult())
    } else if (jobResultPb.getResultCase() === ApiPB.JobResult.ResultCase.INFERENCE_JOB_RESULT) {
      setJobResultPb(jobResultPb.getInferenceJobResult())
    } else if (jobResultPb.getResultCase() === ApiPB.JobResult.ResultCase.QUANTIZE_JOB_RESULT) {
      setJobResultPb(jobResultPb.getQuantizeJobResult())
    }
  }

  const getJobWithResult = (mountState) => {
    getJob(
      jobId,
      mountState,
      (jobPb) => {
        setJobPbOr404(jobPb)
      },
      () => {
        setTrigger404(true)
      },
    )

    getJobResult(jobId, mountState, (jobResultPb) => {
      setJobResultPbOr404(jobResultPb)
    })

    getProducedModels(
      jobId,
      mountState,
      (modelListPb) => {
        setProducedModelList(modelListPb.getModelsList())
      },
      (err) => {
        console.log(err)
      },
    )
  }

  const checkShareAccess = async (signal) => {
    try {
      await apiFetcher.getJobSharedAccess(jobId, signal)
      setHasShareAccess(true)
    } catch {
      setHasShareAccess(false)
    }
  }

  useIntervalEffect(
    () => {
      let [mountState, tearDownMounted] = useMounted()
      const abortController = new AbortController()

      getJobWithResult(mountState)
      void checkShareAccess(abortController.signal)

      return () => {
        tearDownMounted()
        abortController.abort()
      }
    }, // Poll only if the job is in progress.
    [jobId],
    !trigger404 &&
      jobPb &&
      jobPb.getJobState() != ApiPB.JobState.JOB_STATE_DONE &&
      jobPb.getJobState() != ApiPB.JobState.JOB_STATE_FAILED
      ? POLL_INTERVAL
      : undefined,
  )

  let profileInfo
  let compileInfo
  let quantizeInfo
  let linkInfo
  let layerPlacementInfo = {}
  let layersTableData = []
  let numLayers = 0
  let hasPerLayerOrSegmentTiming = false
  let hasPerLayerCycles = false
  let hasSparkLines = false
  let hasKernels = false

  if (isProfileJob && jobResultPb?.getProfile()) {
    profileInfo = jobResultPb.getProfile()
  } else if (isInferenceJob && jobResultPb?.getDetail()) {
    profileInfo = jobResultPb.getDetail()
  }
  if (isCompileJob && jobResultPb?.getCompileDetail()) {
    compileInfo = jobResultPb.getCompileDetail()
  }
  if (isQuantizeJob && jobResultPb?.getQuantizeDetail()) {
    quantizeInfo = jobResultPb.getQuantizeDetail()
  }
  if (isLinkJob && jobResultPb?.getLinkDetail()) {
    linkInfo = jobResultPb.getLinkDetail()
  }
  if (profileInfo) {
    layersTableData = getLayersTableData(profileInfo)
    hasPerLayerCycles = layersTableData.findIndex((row) => row.execCycles > 0) >= 0
    hasPerLayerOrSegmentTiming = layersTableData.findIndex((row) => row.execTime > 0) >= 0
    hasSparkLines = hasPerLayerOrSegmentTiming || hasPerLayerCycles

    for (let row of layersTableData) {
      if (Array.isArray(row.deleageteOps) && row.deleageteOps.length > 0) {
        hasKernels = true
        break
      }
    }

    for (let layer of profileInfo.getLayerDetailsList()) {
      const unitIndex = layer.getComputeUnit()
      // We have to add unknown layers to breakdown, since they can play
      // an important role on some platforms (Android).
      layerPlacementInfo[unitIndex] = (layerPlacementInfo[unitIndex] ?? 0) + 1
      numLayers += 1
    }
  }

  if (trigger404) {
    return <NotFoundPage />
  } else if (jobPb) {
    // Sort in descending order
    //const computeBucketsSorted = Object.entries(layerPlacementInfoBuckets).sort(([, a], [, b]) => b - a);
    const computeUnitsSorted = Object.entries(layerPlacementInfo).sort(([, a], [, b]) => b - a)

    let computeUnitEls = []
    computeUnitsSorted.map(([unitStr, count], index) => {
      let inner
      let tooltip
      if (Number.parseInt(unitStr) === ApiPB.ComputeUnit.COMPUTE_UNIT_UNSPECIFIED) {
        // Use an icon for unspecified
        inner = <UnspecifiedComputeUnitIcon />
        tooltip = 'Compute unit could not be determined'
      } else {
        // Use name for compute units (the "?" is in case something
        // really goes wrong here, and prevents and empty inner, which
        // cases it to use an avatar icon)
        inner = computeUnitToString(Number.parseInt(unitStr)) || '?'
        tooltip = ''
      }

      // Add colored circle
      computeUnitEls.push(
        <div
          className="vertical-align"
          color="primary"
          style={{ marginTop: '-2px', marginRight: '8px' }}
          key={'chip' + index}
        >
          <Tooltip title={tooltip}>
            <Avatar
              sx={{
                width: '29px',
                height: '29px',
                fontSize: '11px',
                fontWeight: 'bold',
                bgcolor: computeUnitToColor(Number.parseInt(unitStr)),
              }}
            >
              {inner}
            </Avatar>
          </Tooltip>
        </div>,
        <div className="vertical-align" style={{ marginRight: '30px' }} key={'count' + index}>
          <Typography variant="h4" style={{ margin: 0, padding: 0 }} gutterBottom>
            {count}
          </Typography>
        </div>,
      )
    })

    let computeUnitDOM = (
      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{computeUnitEls}</div>
      </>
    )

    // TODO: make this include profile jobs with .mlmodelc target? (#7302)
    const canHaveTarget = isCompileJob || isQuantizeJob || isLinkJob
    const isDone = jobPb.getJobState() === ApiPB.JobState.JOB_STATE_DONE
    const isFailed = jobPb.getJobState() === ApiPB.JobState.JOB_STATE_FAILED
    const jobTypeStr = jobTypeToString(jobType)
    const jobLink = `/jobs/?type=${jobTypeStr}`
    const hasVizGraph = !isInferenceJob && !isLinkJob && jobPb.getHasVizgraph()
    const shouldShowRuntimeLog = (isDone || isFailed) && (isInferenceJob || isProfileJob)
    const shouldShowGenericLog = (isDone || isFailed) && (isCompileJob || isLinkJob || isQuantizeJob)
    const hasTargetTensorType = !isLinkJob && !isQuantizeJob

    const downloadCompiled = (e) => {
      e.preventDefault()
      downloadFile(`jobs/${jobId}/download_compiled_model/`)
      return false
    }

    const downloadOutputDataset = (e) => {
      e.preventDefault()
      const datasetId = jobResultPb.getOutputDatasetId()
      downloadFile(`datasets/${datasetId}/download_data/`)
      return false
    }

    const handleShareButtonClick = () => {
      setIsShareDialogOpen(true)
    }

    const handleShareDialogClose = () => {
      setIsShareDialogOpen(false)
    }

    let targetModelId
    if (canHaveTarget && isDone) {
      targetModelId = jobPb?.getTargetModel()?.getModelId()
    }

    const hasTarget = !!targetModelId
    const intermediateModelList = producedModelList
      .filter((modelPb) => modelPb.getModelId() !== targetModelId)
      .filter((modelPb) => !modelPb.getHidden())
      .reverse()
    let psnrValues = []
    if (quantizeInfo) {
      for (let entry of quantizeInfo.getPsnrMap().entries()) {
        psnrValues.push(entry)
      }
    }

    const capitalizedJobTypeStr = capitalizeFirstLetter(jobTypeStr)
    return (
      <div className="main" data-testid="viewjob-main">
        <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
          {`${capitalizedJobTypeStr} Job Results`}
        </Typography>
        <HeaderPaper style={{ whiteSpace: 'nowrap' }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100%', minWidth: '300px', whiteSpace: 'nowrap' }}
          >
            <Grid container columns={{ xs: 6, sm: 12 }}>
              <Grid item align="left" xs="auto" sx={{ pt: 0.4 }} style={{ padding: 0, margin: 0 }}>
                <Breadcrumbs
                  style={{ padding: 0, margin: 0, display: 'flex', height: '30px' }}
                  component={'div'}
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link to={jobLink} style={{ marginLeft: '8px' }}>
                    Jobs
                  </Link>
                  <span className="idbox">{jobId}</span>
                </Breadcrumbs>
              </Grid>
              <Grid item align="left" xs={2} sx={{ pt: 0.2, pl: 2 }}>
                <div
                  className="vertical-align-elements-padded"
                  title={getJobStateIconTooltip(jobTypeToString(jobType))}
                >
                  <JobStateIcon state={jobPb.getJobState()} type={jobTypeToString(jobType)} />
                  <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {jobStateToString(jobPb.getJobState(), true)}
                  </Typography>
                  <Typography sx={{ display: { xs: 'block', sm: 'none' } }}>
                    {jobStateToString(jobPb.getJobState(), false)}
                  </Typography>
                </div>
              </Grid>
              <Grid item align="right" xs zeroMinWidth style={{ whiteSpace: 'nowrap' }}>
                <Box display="flex" justifyContent="end" gap={1}>
                  {jobPb.getAdminUrl() && (
                    <>
                      <div className="vertical-align">
                        <a href={jobPb.getAdminUrl()} style={{ textDecoration: 'none' }}>
                          <Button
                            sx={{ pt: 0.3, pb: 0.3, display: { xs: 'none', md: 'flex' } }}
                            size="small"
                            variant="outlined"
                          >
                            <AdminPanelSettingsIcon />
                            Admin View
                          </Button>
                        </a>
                      </div>
                      <Divider orientation="vertical" flexItem aria-hidden />
                    </>
                  )}
                  {hasShareAccess && (
                    <div className="vertical-align">
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                        startIcon={<ShareIcon />}
                        onClick={handleShareButtonClick}
                      >
                        Share
                      </Button>
                      <ShareDialog
                        apiFetcher={apiFetcher}
                        jobId={jobId}
                        jobType={jobTypeToString(jobType)}
                        isOpen={isShareDialogOpen}
                        onClose={handleShareDialogClose}
                      />
                    </div>
                  )}
                  {hasVizGraph && (
                    <div className="vertical-align">
                      <Button
                        component={Link}
                        to={`/jobs/${jobId}/visualization`}
                        sx={{ pt: 0.3, pb: 0.3, display: { xs: 'none', md: 'flex' } }}
                        size="small"
                        startIcon={<VisualizeIcon />}
                        variant="outlined"
                      >
                        Visualize
                      </Button>
                    </div>
                  )}
                  {hasTarget && (
                    <div className="vertical-align">
                      <Button
                        onClick={downloadCompiled}
                        sx={{ pt: 0.3, pb: 0.3, display: { xs: 'none', sm: 'flex' } }}
                        size="small"
                        startIcon={<DownloadIcon />}
                        variant="outlined"
                      >
                        Target Model
                      </Button>
                    </div>
                  )}
                  {isInferenceJob && isDone && jobResultPb && (
                    <div className="vertical-align">
                      <Button
                        onClick={downloadOutputDataset}
                        sx={{ pt: 0.3, pb: 0.3, display: { xs: 'none', sm: 'flex' } }}
                        size="small"
                        startIcon={<DownloadIcon />}
                        variant="outlined"
                      >
                        Output Dataset
                      </Button>
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </HeaderPaper>
        <Accordion defaultExpanded id="detailPanel">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="detailPanel-content" id="detailPanel-header">
            <Typography>Information</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ overflow: 'hidden', paddingTop: '0px' }} id="detailPanel-content">
            <JobInfoGrid
              jobPb={jobPb}
              jobId={jobId}
              jobType={jobType}
              profileDetail={profileInfo}
              compileDetail={compileInfo}
              linkDetail={linkInfo}
              hubVersion={hubVersion}
              notices={notices}
            />
          </AccordionDetails>
        </Accordion>
        {hasTarget && (
          <Accordion defaultExpanded id="detailPanel">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="detailPanel-content"
              id="detailPanel-header"
            >
              <Typography>Target Information</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ overflow: 'hidden', paddingTop: '0px' }} id="detailPanel-content">
              <JobTargetInfoGrid jobPb={jobPb} hasTargetTensorType={hasTargetTensorType} psnrValues={psnrValues} />
            </AccordionDetails>
          </Accordion>
        )}
        {isDone && profileInfo && (
          <>
            {/* Inference Metrics */}
            <JobInferenceMetrics
              profileInfo={profileInfo}
              computeUnitDOM={computeUnitDOM}
              numLayers={numLayers}
              layerPlacementInfo={layerPlacementInfo}
              isProfileJob={isProfileJob}
            />
          </>
        )}
        {intermediateModelList.length > 0 && isCompileJob && (
          <Accordion id="intermediateAssets">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="detailPanel-content"
              id="detailPanel-header"
            >
              <Typography>Intermediate Assets</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ overflow: 'hidden', paddingTop: '0px' }} id="detailPanel-content">
              {/* Intermediate Models */}
              <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
                <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
                  <ListItemWithIcon caption="Intermediate Models" icon={<FolderIcon />}>
                    {intermediateModelList.map((modelPb, index) => (
                      <div key={index} className="vertical-align-elements">
                        <div className="scrollable">
                          <Link to={`/models/${modelPb.getModelId()}`}>{modelPb.getName()}</Link>
                        </div>
                        <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
                          <span className="idbox">{modelPb.getModelId()}</span>
                        </div>
                      </div>
                    ))}
                  </ListItemWithIcon>
                </List>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {isDone && isProfileJob && profileInfo && (
          <>
            {/* Detailed Metrics */}
            <JobDetailedMetrics profileInfo={profileInfo} />

            {/* Layer details */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography>Runtime Layer Analysis</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ overflow: 'hidden' }}>
                <Table className="table" style={{ tableLayout: 'auto' }}>
                  <TableHead>
                    <TableRow key="header">
                      <TableCell>Layer</TableCell>
                      <TableCell>Type</TableCell>
                      {hasKernels && (
                        <TableCell>
                          Kernel(s) <InfoButton title={infoButtonKernelsTitle} />
                        </TableCell>
                      )}
                      <TableCell>Placement</TableCell>
                      {hasPerLayerCycles && (
                        <TableCell align="right">
                          Compute Cycles <InfoButton title={infoButtonCyclesTitle} />
                        </TableCell>
                      )}
                      {hasPerLayerOrSegmentTiming && (
                        <TableCell align="right">
                          Timing <InfoButton title={infoButtonTimeTitle} />
                        </TableCell>
                      )}
                      {hasSparkLines && <TableCell></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {layersTableData.map((layer, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div className="ellipsis" title={layer.name}>
                            {layer.name}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="ellipsis" title={layer.typeName}>
                            {layer.typeName}
                          </div>
                        </TableCell>
                        {hasKernels && (
                          <TableCell>
                            <div className="ellipsis display-linebreak">
                              {layer.deleageteOps.map((op, index) => {
                                return <div key={index}>{op}</div>
                              })}
                            </div>
                          </TableCell>
                        )}
                        {layer.placementRowSpan > 0 && (
                          <TableCell rowSpan={layer.placementRowSpan}>
                            <div className="ellipsis">{layer.placement}</div>
                          </TableCell>
                        )}
                        {hasPerLayerCycles && (
                          <TableCell align="right">
                            <div className="ellipsis">{formatNumber(layer.execCycles)}</div>
                          </TableCell>
                        )}
                        {hasPerLayerOrSegmentTiming && layer.execRowSpan > 0 && (
                          <TableCell align="right" rowSpan={layer.execRowSpan}>
                            <div className="ellipsis">{layer.execTime} &#956;s</div>
                          </TableCell>
                        )}
                        {hasSparkLines && layer.execRowSpan > 0 && (
                          <TableCell rowSpan={layer.execRowSpan}>
                            <div
                              className="sparkline"
                              style={{ width: layer.sparklineWidth, background: layer.sparklineColor }}
                            ></div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </>
        )}

        {/* Runtime Config */}
        <JobRuntimeConfig profileDetail={profileInfo} />

        {isFailed && (
          <>
            <Accordion defaultExpanded data-testid="viewjob-result-fail">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="failurePanel-content"
                id="failurePanel-header"
              >
                <Typography>Job Failed</Typography>
              </AccordionSummary>
              <AccordionDetails id="failurePanel-content">
                <ListItemWithIcon caption="Failure Reason" icon={<ErrorOutlineIcon />}>
                  <div className="vertical-align-elements failure-reason scrollable">
                    <pre>{jobPb.getFailureReason() ? jobPb.getFailureReason() : 'Unknown'}</pre>
                  </div>
                  {(isProfileJob || isInferenceJob) && jobPb.getErrorDetails() && (
                    <>
                      <br />
                      <Typography variant="caption" color="gray">
                        Additional Information from the Runtime Log
                      </Typography>
                      <div className="vertical-align-elements failure-reason scrollable">
                        <pre>{jobPb.getErrorDetails()}</pre>
                      </div>
                    </>
                  )}
                </ListItemWithIcon>
                <br />
                <a
                  href={
                    'mailto:ai-hub-support@qti.qualcomm.com?subject=Help%20me%20with%20failed%20job%20' +
                    jobId +
                    '&body=Please%20help%20me%20with%20my%20failed%20job.%20' +
                    'The%20associated%20job%20id%20is%20' +
                    jobId +
                    '.'
                  }
                >
                  Need Help?
                </a>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {shouldShowRuntimeLog && <JobRuntimeLog jobId={jobId} />}
        {shouldShowGenericLog && <JobGenericLog jobId={jobId} name={jobTypeStr} />}
      </div>
    )
  } else {
    return ''
  }
}

ViewJob.propTypes = {
  apiFetcher: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
}
