import PropTypes from 'prop-types'
import { median } from '../common/utils'

export const NOT_AVAILABLE = 'N/A'

export function Metric(props) {
  let className = props.className !== undefined ? props.className : ''
  let value = props.value

  const AvailableMetric = () => {
    return <span className={className}>{value}</span>
  }

  const UnavailableMetric = () => {
    return (
      <span className={className} style={{ color: '#aaa' }}>
        {NOT_AVAILABLE}
      </span>
    )
  }

  return props.value === NOT_AVAILABLE ? <UnavailableMetric /> : <AvailableMetric {...props} />
}

Metric.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export function ArrayMetric(props) {
  const { values, valueFormatter, className } = props

  const AvailableArrayMetric = () => {
    if (values.length === 1) {
      return <span className={className}>{valueFormatter(values[0])}</span>
    }
    return (
      <>
        <p>
          <span className="right-aligned-metric">
            <span style={{ color: '#aaa' }}>(min)</span> {valueFormatter(Math.min(...values))}
          </span>
        </p>
        <p>
          <span className="right-aligned-metric">
            <span style={{ color: '#aaa' }}>(median)</span> {valueFormatter(median(values))}
          </span>
        </p>
      </>
    )
  }

  const UnavailableMetric = () => {
    return (
      <span className={className} style={{ color: '#aaa' }}>
        {NOT_AVAILABLE}
      </span>
    )
  }

  return values.length === 0 ? <UnavailableMetric /> : <AvailableArrayMetric />
}

ArrayMetric.propTypes = {
  values: PropTypes.array.isRequired,
  valueFormatter: PropTypes.func.isRequired,
  className: PropTypes.string,
}
