/* istanbul ignore file */

import { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import { Outlet, Route, Routes, useParams, useSearchParams } from 'react-router-dom'
import { useAuthenticationToken } from './common/hooks/use-authentication-token'
import { getAuthUser, getOrganization, useMounted } from './common/restAPI'
import type { LegacyUserInfo } from './common/types/legacy-types'
import type { OrganizationRawJsProtobuf, UserRawJsProtobuf } from './common/types/raw-javascript-protobuf-types'
import { Footer } from './components/footer'
import Home from './components/Home'
import ListJobs from './components/ListJobs'
import ListModels from './components/ListModels'
import LogIn from './components/LogIn'
import LogInEmail from './components/LogInEmail'
import { NavBar } from './components/navbar'
import { PrivateRoute } from './components/private-route'
import SupportListUsers from './components/SupportListUsers'
import ViewAccount from './components/ViewAccount'
import ViewDevices from './components/ViewDevices'
import ViewModel from './components/ViewModel'
import { ViewJobPage } from './routes/jobs/[id]/page'
import { NotFoundPage } from './routes/not-found-page'

type AuthInfo = {
  readonly isAuthenticated: boolean
  readonly user?: UserRawJsProtobuf
  readonly logout: () => void
}

const NavLayout = ({ authInfo }: { readonly authInfo: AuthInfo }) => (
  <div className="nav-layout">
    <NavBar isAuthenticated={authInfo.isAuthenticated} user={authInfo.user} logout={authInfo.logout} />
    <Outlet />
    <Footer />
  </div>
)

export function App() {
  const { isAuthenticated, clearAuthenticationToken, setAuthenticationToken } = useAuthenticationToken()
  const [userInfo, setUserInfo] = useState<LegacyUserInfo>({ user: undefined, organization: undefined })

  const login = useCallback(
    (user: UserRawJsProtobuf, organization: OrganizationRawJsProtobuf, token?: string) => {
      if (token) {
        setAuthenticationToken(token)
      }
      setUserInfo({ user, organization })
    },
    [setAuthenticationToken],
  )

  const logout = useCallback(() => {
    const hasToken = isAuthenticated
    setUserInfo({
      user: undefined,
      organization: undefined,
    })
    clearAuthenticationToken()
    // If the user has a token, this was a logout based on failed
    // credentials. Force a refresh to prevent rendering a log-in
    // protected page (without information).
    if (hasToken) {
      window.location.reload()
    }
  }, [clearAuthenticationToken, isAuthenticated])

  useEffect(() => {
    // Fetch logged in user data
    if (isAuthenticated) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [mountState, tearDownMounted] = useMounted()

      // Get authenticated user's details
      getAuthUser(
        mountState,
        (info: UserRawJsProtobuf) => {
          // Get authenticated user's organization's details
          getOrganization(
            info.getOrgId(),
            mountState,
            (orgInfo: OrganizationRawJsProtobuf) => {
              login(info, orgInfo)
            },
            () => {
              console.error(`Could not get details for organization: ${info.getOrgId()}`)
              logout()
            },
          )
        },
        () => {
          logout()
        },
      )

      return tearDownMounted
    }
  }, [isAuthenticated, login, logout])

  const HomeWrapper = () => {
    return <Home userInfo={userInfo} />
  }

  const ViewModelWrapper = () => {
    const params = useParams()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return <ViewModel modelId={params.id!} />
  }

  const ViewVisualizationWrapper = () => {
    const params = useParams()
    const ViewVisualizationFullscreen = lazy(() => import('./components/ViewVisualization'))
    return (
      <Suspense>
        <ViewVisualizationFullscreen jobId={params.id} />
      </Suspense>
    )
  }

  const ExperimentalViewJobComparisonWrapper = () => {
    const ExperimentalViewJobComparison = lazy(() => import('./components/ExperimentalViewJobComparison'))
    const [search] = useSearchParams()
    const jobIds = search.get('jobs')?.split(',')
    const groupByDevice = Number.parseInt(search.get('groupByDevice') ?? '1')
    return (
      <Suspense>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <ExperimentalViewJobComparison jobIds={jobIds!} groupByDevice={groupByDevice} />
      </Suspense>
    )
  }

  // List views use one-based indexing in the URL, but zero-based internally
  const ListJobsWrapper = () => {
    const [search] = useSearchParams()
    const page = Number.parseInt(search.get('page') ?? '1') - 1
    const rowsPerPage = search.get('rowsPerPage') ? Number.parseInt(search.get('rowsPerPage') ?? '') : undefined
    const jobType = search.get('type') ?? 'compile'
    // eslint-disable-next-line functional/no-let
    let filteredOwner
    if (search.get('ownerKind') && search.get('ownerName')) {
      filteredOwner = {
        kind: search.get('ownerKind'),
        owner: search.get('ownerName'),
      }
    } else if (userInfo.user) {
      filteredOwner = {
        kind: 'user',
        owner: userInfo.user.getEmail(),
      }
    }

    // Force re-load upon button press (otherwise page > 1 won't return to page == 1)
    const key = Math.random()

    return (
      <ListJobs
        page={page}
        rowsPerPage={rowsPerPage}
        jobType={jobType}
        key={key}
        userInfo={userInfo}
        filteredOwner={filteredOwner}
      />
    )
  }

  const ListModelsWrapper = () => {
    const [search] = useSearchParams()
    const page = Number.parseInt(search.get('page') ?? '1') - 1
    // Force re-load upon button press (otherwise page > 1 won't return to page == 1)
    const key = Math.random()

    // eslint-disable-next-line functional/no-let
    let filteredOwner
    if (search.get('ownerKind') && search.get('ownerName')) {
      filteredOwner = {
        kind: search.get('ownerKind'),
        owner: search.get('ownerName'),
      }
    } else if (userInfo.user) {
      filteredOwner = {
        kind: 'user',
        owner: userInfo.user.getEmail(),
      }
    }

    return <ListModels page={page} key={key} userInfo={userInfo} filteredOwner={filteredOwner} />
  }

  const SupportListUsersWrapper = () => {
    return <SupportListUsers loginCallback={login} logoutCallback={logout} />
  }

  const ViewAccountWrapper = () => {
    const [search] = useSearchParams()
    const tab = search.get('tab') ?? 'account'
    const showPasswordChange = search.has('changePassword')

    return <ViewAccount userInfo={userInfo} tab={tab} showPasswordChange={showPasswordChange} />
  }

  const LogInWrapper = () => {
    const [search] = useSearchParams()
    return <LogIn loginCallback={login} ssoResponseCode={search.get('code')} ssoState={search.get('state')} />
  }

  const LogInEmailWrapper = () => {
    return <LogInEmail loginCallback={login} />
  }

  const authInfo: AuthInfo = { isAuthenticated, user: userInfo.user, logout }
  const NavLayoutWrapper = () => <NavLayout authInfo={authInfo} />

  return (
    <Routes>
      <Route path="/jobs/:id/visualization" element={<PrivateRoute Component={ViewVisualizationWrapper} />} />
      <Route path="/signin" element={<NavLayoutWrapper />}>
        <Route index element={<LogInWrapper />} />
      </Route>
      <Route path="/signin-email" element={<NavLayoutWrapper />}>
        <Route index element={<LogInEmailWrapper />} />
      </Route>
      <Route path="/" element={<PrivateRoute Component={NavLayoutWrapper} />}>
        <Route path="/" element={<HomeWrapper />} />
        <Route path="jobs" element={<ListJobsWrapper />} />
        <Route path="jobs/:id" element={<ViewJobPage />} />
        <Route path="job_comparison" element={<ExperimentalViewJobComparisonWrapper />} />
        <Route path="models" element={<ListModelsWrapper />} />
        <Route path="models/:id" element={<ViewModelWrapper />} />
        <Route path="devices" element={<ViewDevices />} />
        <Route path="support" element={<SupportListUsersWrapper />} />
        <Route path="account" element={<ViewAccountWrapper />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
